import styled from 'styled-components';
import poly1 from '../../images/light-gray-left.svg';
import poly2 from '../../images/light-gray-right.svg';
import Section from '../../molecules/Section/Section';
import { atMinWidth } from '../atoms/breakpoints';
import { colors } from '../atoms/colors';
import { font } from '../atoms/typography';

export const BlogContainer = styled.div`
  background: var(--light-gray);
  padding-top: 0;

  .sidebar {
    @media (min-width: 992px) {
      position: sticky;
      top: 130px;
      z-index: 1000;
    }

    .menu-title {
      font-weight: bold;
      font-size: 28.13px;
      line-height: 120%;
      letter-spacing: -0.3px;
      color: #1d2030;
    }

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 36px;
    }
  }
`;

export const HeaderContainer = styled.div`
  background-image: url(${poly1}), url(${poly2});
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  margin-bottom: 115px;

  @media (max-width: 991px) {
    margin-bottom: 97px;
  }

  @media (max-width: 576px) {
    padding: 0 20px !important;
  }

  .hero-container {
    @media (max-width: 768px) {
      padding-top: 64px !important;
    }
  }

  .bg-trans {
    background-color: transparent !important;

    .content-container {
      max-width: 900px;
    }

    .withSubhead {
      font-weight: bold;
      font-size: 54.93px;
      line-height: 60px;
      letter-spacing: -1.2px;
      color: #1d2030;

      @media (max-width: 575px) {
        font-size: 43.95px;
        line-height: 110%;
        letter-spacing: -0.3px;
        color: #1d2030;
      }
    }

    .subhead {
      font-size: 22.5px;
      line-height: 110%;
      letter-spacing: -0.27px;
      color: #6f7283;
      margin-bottom: 0;

      @media (max-width: 575px) {
        margin-bottom: 18px !important;
      }
    }
  }

  .featured-blog-block {
    position: relative;

    .overlay-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .featured-blog-container {
    display: flex;
    max-width: 1170px;
    box-shadow: 0 16px 80px rgba(106, 114, 158, 0.2);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    pointer-events: none;
    z-index: 1;

    @media (max-width: 1199px) {
      max-width: 770px;
      margin: 0 auto !important;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .image-wrapper {
      @media (max-width: 1199px) {
        max-width: 420px;
      }

      @media (max-width: 767px) {
        max-width: 550px;
      }
    }

    .content-wrapper {
      max-width: 450px;

      @media (max-width: 1199px) {
        max-width: 360px;
      }

      @media (max-width: 767px) {
        max-width: 550px;
      }
    }

    .featured-blog-bg {
      background-color: var(--primary-color);
    }

    .blog-content {
      padding: 32px;

      .blog-category {
        margin-bottom: 16px;

        a {
          position: relative;
          pointer-events: all;
        }

        a {
          text-decoration: none;
        }
      }

      .blog-title {
        ${font('display', 'lg', '700')}
        margin-bottom: 16px;
        color: #171e2a;

        @media (max-width: 991px) {
          ${font('display', 'md', '700')}
          margin-bottom: 16px;
        }
      }

      .blog-desc {
        ${font('text', 'md', '400')}
        color: ${colors.gray[600]};
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        margin-bottom: 35px;

        @media (max-width: 991px) {
          margin-bottom: 16px;
        }
      }

      .publish-date {
        font-size: 16px;
        line-height: 150%;
        color: ${colors.gray[600]};
      }
    }

    .btn-primary {
      color: black !important;
    }

    .gatsby-image-wrapper {
      height: 100%;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      img {
        background-color: var(--primary-color);
        width: 770px;
        height: 100%;
        margin-left: -170px;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 32px;

  @media (min-width: 782px) {
    flex-direction: row;
  }

  .search {
    margin-top: 0 32px;
    font-size: 18px;
    padding-left: 44px;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%237B848F%22%20d%3D%22M508.5%20481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395%20312%20416%20262.5%20416%20208%20416%2093.1%20322.9%200%20208%200S0%2093.1%200%20208s93.1%20208%20208%20208c54.5%200%20104-21%20141.1-55.2V371c0%203.2%201.3%206.2%203.5%208.5l129%20129c4.7%204.7%2012.3%204.7%2017%200l9.9-9.9c4.7-4.7%204.7-12.3%200-17zM208%20384c-97.3%200-176-78.7-176-176S110.7%2032%20208%2032s176%2078.7%20176%20176-78.7%20176-176%20176z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px;

    @media (min-width: 782px) {
      margin-top: 0;
      max-width: 240px;
    }

    ${atMinWidth.lg`
      max-width: 270px;
    `}

    &:hover {
      border-color: #0015ca;
    }

    &:focus {
      box-shadow: none;
      border-color: var(--primary-color);
      background-color: #f9fbfe;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 16px 24px;

  ${atMinWidth.md`
    justify-content: flex-start;
  `}

  ${atMinWidth.lg`
    gap: 16px 24px;
  `}

  .badge {
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 500;
    padding: 8px 12px;
  }
`;

export const BlogListContainer = styled(Section)`
  position: relative;
  z-index: 2;

  @media (max-width: 991px) {
    overflow: hidden;
  }

  @media (max-width: 576px) {
    padding: 0 35px !important;
  }

  > *:first-child {
    padding-top: 0;
  }

  .blog-header {
    h2 {
      ${font('display', 'md', '700')}
      color: ${colors.gray[900]};
      margin-bottom: 0.5rem;
    }
  }

  .blog-card {
    margin-bottom: 32px;

    > div {
      height: 100%;
    }
  }

  h4 {
    font-weight: bold;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 32px;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    .subhead {
      margin-bottom: 0;
      ${font('text', 'md', '400')}
      color: ${colors.gray[600]};
    }

    input {
      max-width: 269px;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  
  .subscribe-form {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;
